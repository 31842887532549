<template>
  <main id="onboarding">
        <img class="prev" src="../assets/chevron-left.svg" alt="next" @click="$emit('move', { transitionType: 'prev', to: '/' })">
        <img class="next" src="../assets/chevron-right.svg" alt="next" @click="$emit('move', { transitionType: 'next', to: '/test' })">
    <section class="content">
      <section class="intro" v-hammer:swipe.left.right="goTo">
        <pager :activePage="2" />
        <h1 class="main" v-if="content">{{content.title}}</h1>
      </section>
      <section class="floating" v-html="content.content"></section>
    </section>
  </main>
</template>

<script>
import pager from '@/components/Pager';

export default {
  name: 'onboarding',
  components: {
    pager
  },
  methods: {
    goTo(e){
      if(e.direction === 2) {
        this.$emit('move', { transitionType: 'next', to: '/test' });
      } else {
        this.$emit('move', { transitionType: 'prev', to: '/' });
      }
    }
  },
  computed: {
    content(){
      return this.$store.getters.onboarding;
    }
  }
}
</script>
<style lang="scss">
@import '../scss/variables';

#onboarding {

  .intro {
    background: $orange !important;
    position: relative;

    .pager {
      position: absolute;
      top: 0;
    }
  }

  .floating {

    h3 {
      margin: 2rem 0 .5rem 0;
    }
  }
  
}
</style>